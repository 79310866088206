import React from "react";

const NotFound = () => {
  return (
    <div>
      <h1>The 404 error</h1>
      <p>was sent to you.</p>
    </div>
  );
};

export default NotFound;